<template>
    <v-row class="moloni-receipts-preview">
        <v-col cols="12" md="9">
            <v-card v-if="receiptData">
                <!-- Header -->
                <v-card-text class="py-9 px-8">
                    <div class="receipt-header d-flex flex-wrap justify-space-between flex-column flex-sm-row">
                        <!-- Left Content -->
                        <div class="mb-8 mb-sm-0">
                            <div class="d-flex align-center mb-6">
                                <v-img :src="appLogo" max-height="30px" max-width="30px" alt="logo" contain class="me-3"></v-img>
                                <span class="text--primary font-weight-bold text-xl">
                                    {{ appName }}
                                </span>
                            </div>
                        </div>
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <!-- Payment Details -->
                <v-card-text class="py-9 px-8">
                    <div class="payment-details d-flex justify-space-between flex-wrap flex-column flex-sm-row">
                        <div class="mb-8 mb-sm-0">
                            <p class="font-weight-medium text-xl text--primary mb-4">{{ getTrad(text).receipt }} {{ receiptData.document_set_name }}/{{ receiptData.number }}</p>
                            <p class="mb-2">
                                <span>{{ getTrad(text).issuedDate }}: </span>
                                <span class="font-weight-semibold">{{ $moment(receiptData.date).format('DD-MM-YYYY') }}</span>
                            </p>
                        </div>
                        <div>
                            <!-- <p class="font-weight-semibold payment-details-header">{{ getTrad(text).to }}:</p> -->
                            <p class="mb-1">
                                {{ receiptData.entity_name }}
                            </p>
                            <p class="mb-1">{{ receiptData.entity_address }}</p>
                            <p class="mb-1">{{ receiptData.entity_zip_code }}, {{ receiptData.entity_city }}</p>
                            <p class="mb-2">
                                {{ receiptData.entity_country }}
                            </p>

                            <p class="mb-0">
                                <strong>{{ getTrad(text).vat }}:</strong>{{ receiptData.entity_vat }}
                            </p>
                        </div>
                    </div>
                </v-card-text>

                <!-- Total -->
                <v-card-text class="py-9 px-8">
                    <div class="receipt-total d-flex justify-space-between flex-column flex-sm-row">
                        <div class="mb-2 mb-sm-0"></div>
                        <div>
                            <table class="w-full">
                                <tr>
                                    <td class="pe-16">{{ getTrad(text).total }}:</td>
                                    <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">{{ receiptData.net_value }}€</th>
                                </tr>
                            </table>
                        </div>
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <!-- Documents -->
                <v-simple-table class="purchased-items-table">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>{{ getTrad(text).documentsColumns.type }}</th>
                                <th>{{ getTrad(text).documentsColumns.number }}</th>
                                <th>{{ getTrad(text).documentsColumns.date }}</th>
                                <th>{{ getTrad(text).documentsColumns.totalLiquid }}</th>
                                <th>{{ getTrad(text).documentsColumns.reconciledValue }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in receiptData.associated_documents" :key="item.associated_id">
                                <td class="text-no-wrap">
                                    {{ documentsTypes.filter((type) => type.document_type_id == item.associated_document.document_type_id)[0].titulo }}
                                </td>
                                <td class="text-no-wrap">
                                    <router-link class="font-weight-medium text-decoration-none" :to="{ path: `/moloni/invoices/${item.associated_document.document_id}` }">
                                        {{ item.associated_document.document_set_name }}/{{ item.associated_document.number }}
                                    </router-link>
                                </td>
                                <td class="text-no-wrap">
                                    {{ $moment(item.associated_document.date).format('DD-MM-YYYY') }}
                                </td>
                                <td class="text-center">{{ item.associated_document.net_value }}€</td>
                                <td class="text-center">{{ item.value }}€</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <!-- MB References -->
                <v-card-text v-if="receiptData.payments.length != 0" class="py-9 px-8">
                    <p class="font-weight-medium text-xl text--primary mb-4">{{ getTrad(text).payments }}</p>
                    <v-simple-table class="purchased-items-table mt-4">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>{{ getTrad(text).paymentColumns.name }}</th>
                                    <th>{{ getTrad(text).paymentColumns.date }}</th>
                                    <th>{{ getTrad(text).paymentColumns.value }}</th>
                                    <th>{{ getTrad(text).paymentColumns.observations }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in receiptData.payments" :key="item.reference_id">
                                    <td class="text-no-wrap">
                                        {{ item.payment_method_name }}
                                    </td>
                                    <td class="text-no-wrap">
                                        {{ $moment(item.date).format('DD-MM-YYYY') }}
                                    </td>
                                    <td class="text-no-wrap">{{ item.value }}€</td>
                                    <td class="text-no-wrap">
                                        {{ item.notes }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table></v-card-text
                >
            </v-card>
        </v-col>

        <!-- Right Column: Receipt Action -->
        <v-col cols="12" md="3">
            <v-card>
                <v-card-text>
                    <!-- <v-btn color="primary" class="mb-3" block @click="isReceiptSendSidebarActive = true">
                        <v-icon class="me-2" left>
                            {{ icons.mdiSendOutline }}
                        </v-icon>
                        <span>Send Receipt</span>
                    </v-btn> -->
                    <v-btn :disabled="receiptData.status == 0" class="mb-3" color="primary" block @click="downloadReceipt"
                        ><v-icon class="me-2" left>
                            {{ icons.mdiDownload }}
                        </v-icon>
                        <span>Download</span>
                    </v-btn>
                    <v-btn :disabled="receiptData.status == 0" class="mb-3" color="primary" block @click="printReceipt"
                        ><v-icon class="me-2" left>
                            {{ icons.mdiPrinter }}
                        </v-icon>
                        <span>Print</span>
                    </v-btn>
                    <!-- <v-btn class="mb-3" color="secondary" block outlined :to="{ name: 'apps-receipt-edit', params: { id: $route.params.id } }"> Edit Receipt </v-btn>
                    <v-btn color="success" block @click="isAddPaymentSidebarActive = true">
                        <v-icon class="me-2" left>
                            {{ icons.mdiCurrencyUsd }}
                        </v-icon>
                        <span>Add Payment</span>
                    </v-btn> -->
                </v-card-text>
            </v-card>
        </v-col>

        <!-- receipt send drawer -->
        <!-- <receipt-sidebar-send-receipt v-model="isReceiptSendSidebarActive"></receipt-sidebar-send-receipt> -->

        <!-- receipt add payment drawer -->
        <!-- <receipt-sidebar-add-payment v-model="isAddPaymentSidebarActive"></receipt-sidebar-add-payment> -->
    </v-row>
</template>

<script>
    import { ref, onUnmounted } from '@vue/composition-api';
    import store from '@/store';
    import router from '@/router';
    import { mdiSendOutline, mdiDownload, mdiPrinter, mdiAlertCircleOutline, mdiCheckCircleOutline } from '@mdi/js';

    import themeConfig from '@themeConfig';
    import receiptStoreModule from '../receiptStoreModule';
    import getTrad from '../../../i18n/getTrad';

    export default {
        components: {
            /* ReceiptSidebarSendReceipt,
            ReceiptSidebarAddPayment, */
        },
        mixins: [getTrad],
        setup() {
            const receiptData = ref(null);
            const documentsTypes = ref(null);

            const isReceiptSendSidebarActive = ref(false);
            const isAddPaymentSidebarActive = ref(false);
            // Receipt Description
            // ? Your real data will contain this information

            // ————————————————————————————————————
            //* ——— Store Registration
            // ————————————————————————————————————

            const INVOICE_APP_STORE_MODULE_NAME = 'moloni-receipts';

            // Register module
            if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
                store.registerModule(INVOICE_APP_STORE_MODULE_NAME, receiptStoreModule);
            }

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
            });

            // ————————————————————————————————————
            //* ——— Receipt
            // ————————————————————————————————————

            store
                .dispatch('moloni-receipts/fetchReceipt', { id: router.currentRoute.params.id })
                .then((response) => {
                    receiptData.value = response.data.data;
                })
                .catch((error) => {
                    receiptData.value = undefined;
                });
            // ————————————————————————————————————
            //* ——— Documents Type
            // ————————————————————————————————————

            store
                .dispatch('moloni-receipts/fetchDocumentsType')
                .then((response) => {
                    documentsTypes.value = response.data.data;
                })
                .catch((error) => {
                    if (error.code == 400) {
                        documentsTypes.value = undefined;
                    }
                });

            const printReceipt = () => {
                window.print();
            };

            const downloadReceipt = () => {
                store
                    .dispatch('moloni-receipts/fetchReceiptDownloadLink', { id: router.currentRoute.params.id })
                    .then((response) => {
                        console.log(response.data);
                        document.location.href = response.data.data.url;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            };

            const text = 'views.receipt.preview';

            return {
                receiptData,
                documentsTypes,
                // receiptDescription,
                printReceipt,
                downloadReceipt,
                // drawer
                isReceiptSendSidebarActive,
                isAddPaymentSidebarActive,

                // themeConfig
                appName: themeConfig.app.name,
                appLogo: themeConfig.app.logo,

                icons: {
                    mdiSendOutline,
                    mdiPrinter,
                    mdiDownload,
                    mdiAlertCircleOutline,
                    mdiCheckCircleOutline,
                },
                text,
            };
        },
    };
</script>

<style lang="scss">
    @import '~@core/preset/preset/apps/moloni.scss';

    @media print {
        .v-application {
            background: none !important;
        }

        .app-navigation-menu,
        .v-app-bar,
        .v-footer,
        .product-buy-now,
        .moloni-receipts-preview.row .col-12:last-of-type {
            display: none !important;
        }

        .moloni-receipts-preview.row .col-12:first-child {
            max-width: 100% !important;
            flex: 1 !important;
        }

        .v-main,
        .app-content-container {
            padding: 0px !important;
        }

        .v-card {
            box-shadow: none !important;
        }

        .moloni-receipts-preview {
            .receipt-header,
            .payment-details,
            .receipt-total {
                &.d-flex {
                    flex-direction: row !important;
                }
            }
        }
    }
</style>
